import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogContentBody from "../../components/pages/BlogDetails/BlogContentBody"

const WhatIsCarbonAccounting = () => {
  return (
    <Layout>
      <Seo
        title="Carbon Accounting: Everything You Need To Know"
        description={`Discover everything about carbon accounting. Learn the fundamentals, benefits, and strategies for managing and reducing your carbon footprint.`}
      />

      {/* This slug is matched with blogs in Content */}
      <BlogContentBody slug={`what-is-carbon-accounting`} />
    </Layout>
  )
}

export default WhatIsCarbonAccounting
